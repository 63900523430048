import * as React from "react";
import { Link } from "react-router-dom";

export default class AdventureList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adventureCollection: [
        {
          slug: "ancient-trails",
          name: "Ancient Trails",
          thumbnail: { url: "/img/3.webp" },
        },
        {
          slug: "laugavegur",
          name: "Laugavegur",
          thumbnail: { url: "/img/9.webp" },
        },
        {
          slug: "westfjords",
          name: "Westfjords",
          thumbnail: { url: "/img/6.webp" },
        },
        {
          slug: "fire-and-ice",
          name: "Fire and Ice",
          thumbnail: { url: "/img/1.webp" },
        },
        {
          slug: "mountain-retreat",
          name: "Mountain Retreat",
          thumbnail: { url: "/img/5.webp" },
        },
        {
          slug: "northern-lights",
          name: "Northern Lights",
          thumbnail: { url: "/img/2.webp" },
        },
      ],
    };
  }

  render() {
    return (
      <div
        id="adventures"
        className="flex flex-col w-full max-w-[1408px] py-16 px-4 laptop:px-16"
      >
        <ul className="grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-3 desktop:grid-cols-4 gap-8 text-4xl font-['norse']">
          {this.state.adventureCollection.length > 0 ? (
            this.state.adventureCollection
              .sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                else return 1;
              })
              .map((e) => (
                <Link to={"/adventures/" + e.slug}>
                  <li className="flex flex-col w-full aspect-[3/4] group">
                    <img
                      src={e.thumbnail.url}
                      className="w-full h-full object-cover group-hover:brightness-110"
                    />
                    <div className="w-full p-4 text-center">{e.name}</div>
                  </li>
                </Link>
              ))
          ) : (
            <span className="text-4xl col-span-full text-center">
              No Adventures Found
            </span>
          )}
        </ul>
        <div className="w-full mt-16 h-96 relative group">
          <img
            src="/img/12.webp"
            alt="A man standing on a cliff overlooking Hrafntinnusker"
            className="w-full object-cover h-full group-hover:brightness-110"
          />
          <div className="absolute left-0 top-0 bottom-0 right-0 bg-black bg-opacity-20"></div>
          <div className="absolute h-full w-auto items-center justify-center top-0 left-0 flex flex-col">
            <div className="flex flex-col justify-center gap-4 w-full h-full p-4 laptop:p-16">
              <span className="text-3xl tablet:text-4xl laptop:text-5xl desktop:text-6xl text-white font-black drop-shadow-md">
                Let's plan your own custom tailored itinerary!
              </span>
              <div className="flex flex-row text-xl desktop:text-3xl font-black">
                <Link
                  to="/private-adventure"
                  className="bg-neutral-100 hover:bg-neutral-200 p-4"
                >
                  PRIVATE ADVENTURE
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
