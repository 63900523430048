import * as React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import Footer from "../components/Footer";

export default class TermsAndConditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navActive: false,
      screenWidth: window.innerWidth,
    };

    this.hideNav = this.hideNav.bind(this);
    this.showNav = this.showNav.bind(this);
  }

  hideNav() {
    this.setState({ navActive: false });
  }

  showNav() {
    this.setState({ navActive: true });
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    if (this.state.navActive && window.innerWidth < 1440) {
      document.body.classList.add("overflow-hidden");
    } else document.body.classList.remove("overflow-hidden");

    window.addEventListener("resize", () => {
      this.setState({
        screenWidth: window.innerWidth,
      });
      if (this.state.navActive && window.innerWidth < 1440) {
        document.body.classList.add("overflow-hidden");
      } else document.body.classList.remove("overflow-hidden");
    });
  }

  componentDidUpdate() {
    if (this.state.navActive && window.innerWidth < 1440) {
      document.body.classList.add("overflow-hidden");
    } else document.body.classList.remove("overflow-hidden");
  }

  render() {
    return (
      <div className="App flex flex-col items-center">
        <div className="bg-red-500 text-white w-full p-2">
          The website is in development so you can expect some errors or display
          problems. Not all buttons and links work.
        </div>

        <header
          className={
            "flex flex-col items-center desktop:justify-center w-full group" +
            (this.state.navActive & (this.state.screenWidth < 1440)
              ? " absolute"
              : " h-96 relative")
          }
        >
          <div
            className={
              "z-40 flex flex-row items-center justify-center w-full desktop:h-auto desktop:absolute desktop:top-0 desktop:left-0" +
              (this.state.navActive & (this.state.screenWidth < 1440)
                ? " h-screen flex-col"
                : "")
            }
          >
            <div
              className={
                "w-full h-full desktop:max-w-[1280px] flex bg-neutral-900 text-white desktop:m-16" +
                (this.state.navActive & (this.state.screenWidth < 1440)
                  ? " flex-col items-center justify-center"
                  : " flex-row bg-opacity-90")
              }
            >
              <a href="/">
                <div className="p-8 hover:bg-neutral-900">
                  <img
                    src="/runes.svg"
                    alt="Norse Logo"
                    className="invert w-48"
                  />
                </div>
              </a>
              <div
                id="navlinks"
                className={
                  "flex flex-col desktop:flex-row desktop:ml-auto text-xl font-black" +
                  (!this.state.navActive ? " ml-auto" : "")
                }
              >
                {this.state.navActive || this.state.screenWidth >= 1440 ? (
                  <>
                    <HashLink
                      onClick={this.hideNav}
                      className="p-8 hover:bg-neutral-900 h-full flex flex-col justify-center"
                      to="/#adventures"
                    >
                      ADVENTURES
                    </HashLink>
                    <Link
                      onClick={this.hideNav}
                      className="p-8 hover:bg-neutral-900 h-full flex flex-col justify-center"
                      to="/about-us"
                    >
                      ABOUT US
                    </Link>
                    <a
                      onClick={this.hideNav}
                      className="p-8 hover:bg-neutral-900 h-full flex flex-col justify-center"
                      href="#contact-us"
                    >
                      CONTACT US
                    </a>
                  </>
                ) : (
                  <button onClick={this.showNav} className="text-3xl p-8">
                    ☰
                  </button>
                )}
              </div>
            </div>
          </div>

          {this.state.navActive && (
            <button
              onClick={this.hideNav}
              className="text-3xl p-8 absolute top-0 right-0 text-white z-50"
            >
              ☰
            </button>
          )}

          <div className="w-full h-full object-cover group-hover:brightness-110 bg-blue-500" />
          <div className="absolute w-full h-full bg-black bg-opacity-20"></div>
          <div className="absolute w-full h-full flex flex-col items-start justify-end p-4 laptop:p-16 max-w-[1408px]">
            <div className="flex flex-col gap-4 mb-12 laptop:mb-0">
              <span className="text-4xl text-white font-black drop-shadow-md uppercase">
                Terms and Conditions
              </span>
            </div>
          </div>
        </header>

        <main class="flex flex-col p-4 laptop:p-16 max-w-[1408px] py-16">
          <section class="flex flex-col gap-8">
            <div class="flex flex-col gap-4">
              <p class="text-xl font-medium">
                Please read these terms carefully before you book as it
                constitutes a contract agreement from the time booking was made.
              </p>

              <p class="text-lg">
                All bookings, cancellations or changes to bookings must be made
                through our website's booking engine, our partners, or via
                email.
              </p>

              <p class="text-lg">
                Payment can be made by credit card in ISK, USD or by other
                methods accepted by us via email, and the exchange rate from the
                Central Bank of Iceland shall be used.
              </p>

              <p class="text-lg">
                All tours must be fully paid before booking will be considered
                confirmed, exceptions can be made via email.
              </p>

              <p class="text-lg">
                Upon booking you accept our{" "}
                <Link to="../cancellation-policy">
                  <span class="text-sky-700 hover:text-sky-300">
                    cancellation policy
                  </span>
                </Link>
                .
              </p>

              <p class="mt-4 text-xl font-black">
                RESPONSIBILITY OF PARTICIPANTS
              </p>

              <p class="text-lg">
                The person who makes the booking accepts these conditions on
                behalf of all the participants in the group and is responsible
                for all payments due.
              </p>

              <p class="text-lg">
                All trips are undertaken on the responsibility of its
                participants. We do not assume any responsibility for accidents
                that are caused by its customers, or can be traced to their own
                actions, or failure to use equipment or clothing as recommended
                by their guide, or failure to respond to safety instruction from
                their guide.All tours must be fully paid before booking will be
                considered confirmed, exceptions can be made via email.
              </p>

              <p class="text-lg">
                Individual travel insurance is not included in the tour price
                and all medical costs and other costs involved must be paid by
                the client. It is therefore strongly recommended that
                participants take out personal travel and/or medical insurance
                prior to departure.
              </p>

              <p class="text-lg">
                Upon booking you are responsible for all details to be correct.
                Failure to communicate with our customers, or if inadequate or
                wrong information is provided, may result in the cancellation of
                the trip, with no refund available.
              </p>

              <p class="mt-4 text-xl font-black">LIABILITY</p>

              <p class="text-lg">
                All trips are weather and condition dependent and we reserve the
                right to change your itinerary or cancel your trip last minute
                with your safety in mind.
              </p>

              <p class="text-lg">
                Northern Light trips carry the inherent risk that no northern
                lights may be found. In that case, we will offer its customers a
                rebooking of their tour on a different date, but will not offer
                refunds.
              </p>

              <p class="text-lg">
                We act in good faith and cannot be held responsible for any
                schedule changes caused by weather or road conditions, any loss,
                damage, accidents, sickness or acts of god such as natural
                catastrophes, strikes or any other irregularities beyond our
                control.
              </p>

              <p class="text-lg">
                Luggage and personal belongings is at the owner’s risk at all
                times.
              </p>

              <p class="mt-4 text-xl font-black">LEGAL</p>

              <p class="text-lg">
                Icelandic law shall apply to any legal issues that arise between
                the two parties.
              </p>
            </div>
          </section>
        </main>

        <Footer />
      </div>
    );
  }
}
