import * as React from "react";
import { Link } from "react-router-dom";

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <footer className="bg-neutral-900 text-white w-full min-h-screen flex flex-col justify-center">
        <div id="contact-us" className="w-full flex flex-col items-center">
          <div className="grid grid-cols-1 laptop:grid-cols-2 w-full max-w-[1280px] justify-center items-center">
            <div className="flex flex-col items-center font-bold text-xl desktop:text-3xl">
              <a
                href="mailto:adventures@norse.is"
                className="flex flex-row w-full tablet:w-auto text-center gap-4 p-4 items-center justify-center hover:bg-neutral-800"
              >
                <span>&#9993;</span>
                <span>adventures@norse.is</span>
              </a>
              <a
                href="tel:+3548888889"
                className="flex flex-row w-full tablet:w-auto text-center gap-4 p-4 items-center justify-center hover:bg-neutral-800"
              >
                <span>&#9742;</span>
                <span>+354-888-8889</span>
              </a>
            </div>
            <div className="flex flex-col gap-8 z-10 w-full p-16 pb-8 justify-center items-center text-center laptop:text-left">
              <img
                src="/runes.svg"
                alt="Norse Logo"
                width={200}
                className="invert"
              />
              <div className="flex flex-col">
                <span className="font-bold">
                  Fjallaþjónustan ehf. / Norse Adventures
                </span>
                <span>Id. 570216-0230</span>
                <span>Brúarfljót 7e, 270 Mosfellsbær</span>
              </div>
              <div className="flex flex-col tablet:flex-row w-full max-w-[1280px] justify-center items-center">
                <Link
                  to="/terms-and-conditions"
                  className="p-4 w-full tablet:w-auto text-center hover:bg-neutral-800"
                >
                  Terms and Conditions
                </Link>
                <Link
                  to="/cancellation-policy"
                  className="p-4 w-full tablet:w-auto text-center hover:bg-neutral-800"
                >
                  Cancellation Policy
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
