import * as React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import Footer from "../components/Footer";

export default class Laugavegur extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navActive: false,
      screenWidth: window.innerWidth,
    };

    this.hideNav = this.hideNav.bind(this);
    this.showNav = this.showNav.bind(this);
  }

  hideNav() {
    this.setState({ navActive: false });
  }

  showNav() {
    this.setState({ navActive: true });
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    if (this.state.navActive && window.innerWidth < 1440) {
      document.body.classList.add("overflow-hidden");
    } else document.body.classList.remove("overflow-hidden");

    window.addEventListener("resize", () => {
      this.setState({
        screenWidth: window.innerWidth,
      });
      if (this.state.navActive && window.innerWidth < 1440) {
        document.body.classList.add("overflow-hidden");
      } else document.body.classList.remove("overflow-hidden");
    });

    let bokunScript = document.createElement("script");
    bokunScript.src =
      "https://widgets.bokun.io/assets/javascripts/apps/build/BokunWidgetsLoader.js?bookingChannelUUID=b8f8ea4f-4784-493c-bad6-a8541da2e38f";
    document.body.appendChild(bokunScript);
  }

  componentDidUpdate() {
    if (this.state.navActive && window.innerWidth < 1440) {
      document.body.classList.add("overflow-hidden");
    } else document.body.classList.remove("overflow-hidden");
  }

  render() {
    return (
      <div className="App flex flex-col items-center">
        <div className="bg-red-500 text-white w-full p-2">
          The website is in development so you can expect some errors or display
          problems. Not all buttons and links work.
        </div>

        <header
          className={
            "flex flex-col items-center desktop:justify-center w-full group" +
            (this.state.navActive & (this.state.screenWidth < 1440)
              ? " absolute"
              : " h-screen relative")
          }
        >
          <div
            className={
              "z-40 flex flex-row items-center justify-center w-full desktop:h-auto desktop:absolute desktop:top-0 desktop:left-0" +
              (this.state.navActive & (this.state.screenWidth < 1440)
                ? " h-screen flex-col"
                : "")
            }
          >
            <div
              className={
                "w-full h-full desktop:max-w-[1280px] flex bg-neutral-900 text-white desktop:m-16" +
                (this.state.navActive & (this.state.screenWidth < 1440)
                  ? " flex-col items-center justify-center"
                  : " flex-row bg-opacity-90")
              }
            >
              <a href="/">
                <div className="p-8 hover:bg-neutral-900">
                  <img
                    src="/runes.svg"
                    alt="Norse Logo"
                    className="invert w-48"
                  />
                </div>
              </a>
              <div
                id="navlinks"
                className={
                  "flex flex-col desktop:flex-row desktop:ml-auto text-xl font-black" +
                  (!this.state.navActive ? " ml-auto" : "")
                }
              >
                {this.state.navActive || this.state.screenWidth >= 1440 ? (
                  <>
                    <HashLink
                      onClick={this.hideNav}
                      className="p-8 hover:bg-neutral-900 h-full flex flex-col justify-center"
                      to="/#adventures"
                    >
                      ADVENTURES
                    </HashLink>
                    <Link
                      onClick={this.hideNav}
                      className="p-8 hover:bg-neutral-900 h-full flex flex-col justify-center"
                      to="/about-us"
                    >
                      ABOUT US
                    </Link>
                    <a
                      onClick={this.hideNav}
                      className="p-8 hover:bg-neutral-900 h-full flex flex-col justify-center"
                      href="#contact-us"
                    >
                      CONTACT US
                    </a>
                  </>
                ) : (
                  <button onClick={this.showNav} className="text-3xl p-8">
                    ☰
                  </button>
                )}
              </div>
            </div>
          </div>

          {this.state.navActive && (
            <button
              onClick={this.hideNav}
              className="text-3xl p-8 absolute top-0 right-0 text-white z-50"
            >
              ☰
            </button>
          )}

          <video
            className="w-full h-full object-cover group-hover:brightness-110"
            loop
            autoPlay
            muted
          >
            <source src="/img/Brennisteinsalda.mp4" type="video/mp4"></source>
            Your browser does not support the video tag.
          </video>
          <div className="absolute w-full h-full bg-black bg-opacity-20"></div>
          <div className="absolute h-full w-full flex flex-col items-start justify-end p-4 laptop:p-16 max-w-[1408px]">
            <div className="flex flex-col gap-4 mb-16">
              <span className="text-4xl tablet:text-5xl laptop:text-6xl laptop-lg:text-7xl desktop:text-8xl text-white font-black drop-shadow-md uppercase">
                Laugavegur
              </span>
            </div>
          </div>
        </header>

        <div className="description-content flex flex-col p-4 laptop:p-16 w-full max-w-[1408px]">
          <div className="flex flex-row gap-8">
            <div className="flex flex-row">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-10 w-10 block mr-4 text-stone-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span>
                <span className="block font-bold">Duration</span>6 Days
              </span>
            </div>
            <div className="flex flex-row">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-10 w-10 block mr-4 text-stone-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span>
                <span className="block font-bold">Difficulty</span>
                Medium
              </span>
            </div>
            <div className="flex flex-row">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-10 w-10 block mr-4 text-stone-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M12 1.586l-4 4v12.828l4-4V1.586zM3.707 3.293A1 1 0 002 4v10a1 1 0 00.293.707L6 18.414V5.586L3.707 3.293zM17.707 5.293L14 1.586v12.828l2.293 2.293A1 1 0 0018 16V6a1 1 0 00-.293-.707z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span>
                <span className="block font-bold">Total Distance</span>
                55 km (34 mi)
              </span>
            </div>
            <div className="flex flex-row">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-10 w-10 block mr-4 text-stone-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
              </svg>
              <span>
                <span className="block font-bold">Accommodation</span>
                Mountain Huts
              </span>
            </div>
            <div className="flex flex-row">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-10 w-10 block mr-4 text-stone-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
              </svg>
              <span>
                <span className="block font-bold">Group Size</span>
                Up to 14 participants
              </span>
            </div>
          </div>
          <div className="mt-8">
            <p className="text-xl font-bold text-black lg:mx-auto">
              Description
            </p>
            <p className="mt-4 text-xl text-gray-500 lg:mx-auto">
              The Laugavegur trail is the most popular hiking trail in Iceland.
            </p>
            <p className="mt-2 text-xl text-gray-500 lg:mx-auto">
              <img
                className="w-4 h-4 inline -mt-1 mr-1 object-scale-down"
                src="/img/national.png"
              />
              <span className="text-black">National Geographic</span> listed it
              as one of the most beautiful trails in the world.
            </p>
            <p className="mt-4 text-xl text-gray-500 lg:mx-auto">
              The trail is unusual and very different from many trails around
              the world. It has an incredible variety of landscape and as you
              hike you will see everything from colorful rhyolite mountains, to
              black sand deserts to green mossy mountains and lushious mountain
              ridges.
            </p>
            <p className="mt-4 text-xl text-gray-500 lg:mx-auto">
              We will pass wheezing hot springs with steam coming from the
              ground, black obsidian lava fields, lakes clear as mirrors and
              incredible contrasts of green mossy mountains standing out from
              the sand, a green lushious mountain ridge full of rivers bending
              in all sorts of ways.
            </p>
            <p className="mt-4 text-xl text-gray-500 lg:mx-auto">
              Your luggage will be transported between huts making the hike even
              more enjoyable as you only need to carry a smaller day-pack. We
              travel in small groups to keep it as personal as possible, and we
              cook all meals together as a group.
            </p>
            <p className="mt-4 text-xl text-gray-500 lg:mx-auto">
              Hiking in the Highlands of Iceland is an amazing experience, and
              Norse Adventures will ensure a worry-free and educational
              experience.
            </p>
            <p className="mt-4 text-xl text-stone-600 flex text-left">
              <svg
                className="w-6 h-6 inline text-stone-600 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 1.944A11.954 11.954 0 012.166 5C2.056 5.649 2 6.319 2 7c0 5.225 3.34 9.67 8 11.317C14.66 16.67 18 12.225 18 7c0-.682-.057-1.35-.166-2.001A11.954 11.954 0 0110 1.944zM11 14a1 1 0 11-2 0 1 1 0 012 0zm0-7a1 1 0 10-2 0v3a1 1 0 102 0V7z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              Please follow our packing list found in the FAQ below.
            </p>
            <p className="mt-4 text-xl text-amber-600 flex text-left">
              <svg
                className="w-6 h-6 inline text-amber-600 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 1.944A11.954 11.954 0 012.166 5C2.056 5.649 2 6.319 2 7c0 5.225 3.34 9.67 8 11.317C14.66 16.67 18 12.225 18 7c0-.682-.057-1.35-.166-2.001A11.954 11.954 0 0110 1.944zM11 14a1 1 0 11-2 0 1 1 0 012 0zm0-7a1 1 0 10-2 0v3a1 1 0 102 0V7z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              Keep in mind that in Iceland the weather is very unpredictable and
              may change suddenly.
            </p>
          </div>
        </div>

        <div className="flex flex-col p-4 laptop:p-16 w-full max-w-[1408px] pt-0 laptop:pt-0">
          <h2 className="text-base text-stone-800 font-semibold tracking-wide uppercase">
            ITINERARY
          </h2>

          <div id="accordion-open" data-accordion="open" className="mt-4">
            <h2 id="accordion-open-heading-1">
              <button
                type="button"
                className="flex items-center justify-between w-full p-5 font-medium text-left border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-800 bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white"
                data-accordion-target="#accordion-open-body-1"
                aria-expanded="true"
                aria-controls="accordion-open-body-1"
              >
                <span className="flex items-center text-base sm:text-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10 mr-4 text-stone-500 hidden sm:block"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="font-bold mr-1">
                    D<span className="hidden sm:inline">ay </span>1:
                  </span>
                  Reykjavik - Landmannalaugar
                </span>
                <svg
                  data-accordion-icon=""
                  className="w-6 h-6 shrink-0 rotate-180"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </h2>
            <div
              id="accordion-open-body-1"
              className="hidden"
              aria-labelledby="accordion-open-heading-1"
            >
              <div className="p-5 font-light border border-b-0 border-gray-200 dark:border-gray-700">
                <p className="text-gray-500 dark:text-gray-400">
                  We meet the group at the meeting point in
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Reykjavik
                  </a>
                  city,
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    BSI Bus Terminal
                  </a>
                  and load the luggage into the bus.
                </p>
                <p className="mt-2 text-gray-500 dark:text-gray-400">
                  The drive to
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Landmannalaugar
                  </a>
                  is a magical experience as we go through many different
                  contrasts of landscape and vegetation. We move from the
                  populated part of Iceland into the desolate black highlands
                  where conditions are rough and tough. We will see many
                  beautiful sights along the way in
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Fjallabak
                  </a>
                  nature reserve as we traverse the mountain road.
                </p>
                <p className="mt-2 text-gray-500 dark:text-gray-400">
                  When we are close, we will start to see the incredible colors
                  of
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Landmannalaugar
                  </a>
                  . Black, red, orange, green, blue, all coming together in the
                  rhyolite mountains. We arrive at the hut and start getting to
                  know each other and the guide will go over the next few days
                  with the group and prepare for the hike to come.
                </p>
                <p className="mt-2 text-gray-500 dark:text-gray-400">
                  In the evening, we will cook our first highland meal together
                  and have a good time. Those who are interested in really
                  absorbing the nature can take an evening dip into the
                  geothermally heated pool (up to 40°C!) that the name of the
                  place derives from, as the name
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Landmannalaugar
                  </a>
                  directly translates to "The People's Pools".
                </p>
              </div>
            </div>
            <h2 id="accordion-open-heading-2">
              <button
                type="button"
                className="flex items-center justify-between w-full p-5 font-medium text-left border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-800 bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white"
                data-accordion-target="#accordion-open-body-2"
                aria-expanded="true"
                aria-controls="accordion-open-body-2"
              >
                <span className="flex items-center text-base sm:text-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10 mr-4 text-stone-500 hidden sm:block"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="font-bold mr-1">
                    D<span className="hidden sm:inline">ay </span>2:
                  </span>
                  Landmannalaugar - Alftavatn
                </span>
                <svg
                  data-accordion-icon=""
                  className="w-6 h-6 shrink-0 rotate-180"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </h2>
            <div
              id="accordion-open-body-2"
              className="hidden"
              aria-labelledby="accordion-open-heading-2"
            >
              <div className="p-5 font-light border border-b-0 border-gray-200 dark:border-gray-700">
                <div className="flex flex-row">
                  <div className="flex flex-row m-4 ml-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-10 w-10 block mr-4 text-stone-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12 1.586l-4 4v12.828l4-4V1.586zM3.707 3.293A1 1 0 002 4v10a1 1 0 00.293.707L6 18.414V5.586L3.707 3.293zM17.707 5.293L14 1.586v12.828l2.293 2.293A1 1 0 0018 16V6a1 1 0 00-.293-.707z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>
                      <span className="block font-bold">Distance</span>
                      24 km (15 mi)
                    </span>
                  </div>
                  <div className="flex flex-row m-4">
                    <span>
                      <span className="block font-bold">Time</span>
                      8-10 hours
                    </span>
                  </div>
                </div>
                <p className="text-gray-500 dark:text-gray-400">
                  The first part leads up to the lava field of
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Laugahraun
                  </a>
                  , crosses it, then heads downhill before heading up again to
                  the plateau, just below
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Mt. Brennisteinsalda
                  </a>
                  . It is a good idea to stop here and look around at the
                  stunning views, flora, mountains and gorges in all colours of
                  the rainbow. The trail then continues further up on to the
                  plateau, where small ravines cut into the landscape as the
                  rivers shape the soft mountain. The way ahead is all uphill,
                  but it not extremely steep.
                </p>
                <p className="mt-2 text-gray-500 dark:text-gray-400">
                  The next destination is
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Stórihver
                  </a>
                  , a beautiful vegetation spot right next to a whizzing geyser
                  where you can stop, rest your legs and get a bite from the
                  provision box. It is approximately an hour walk from here, up
                  to the hut
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Höskuldsskáli
                  </a>
                  at
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Hrafntinnusker
                  </a>
                  . This part of the trail is often covered with snow. Here is
                  also the greatest risk of fog.
                </p>

                <p className="mt-2 text-gray-500 dark:text-gray-400">
                  The path from
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Hrafntinnusker
                  </a>
                  runs along the slopes of
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Reykjafjöll
                  </a>
                  . The area is a valley bottom which is mostly flat, but there
                  are a few ravines that should be crossed extra carefully since
                  they are often half-full of snow. Next, the direction is taken
                  to the west of
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Kaldaklofsfjöll
                  </a>
                  and up to the spine between them and
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Jökulgil
                  </a>
                  .
                </p>

                <p className="mt-2 text-gray-500 dark:text-gray-400">
                  The trail now goes up and down through few ravines until it
                  reaches the edge of
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Jökultungur
                  </a>
                  , with incomparable views over the whole trail and the three
                  glaciers
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Tindfjallajökull
                  </a>
                  ,
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Eyjafjallajökull
                  </a>
                  and
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Mýrdalsjökull
                  </a>
                  .
                </p>

                <p className="mt-2 text-gray-500 dark:text-gray-400">
                  The road down
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Jökultungur
                  </a>
                  is quite steep and rocky, so be careful. At the bottom of
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Jökultungur
                  </a>
                  the
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Grashagakvísl
                  </a>
                  river awaits and can in some cases be crossed on a snow
                  bridge. If not, you need to ford by wading on foot. The water
                  in
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Grashagakvísl
                  </a>
                  has fresh and good water to drink. From there is an easy route
                  southwest to the hut in
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Álftavatn
                  </a>
                  lake, where we will spend the night.
                </p>
              </div>
            </div>
            <h2 id="accordion-open-heading-3">
              <button
                type="button"
                className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
                data-accordion-target="#accordion-open-body-3"
                aria-expanded="false"
                aria-controls="accordion-open-body-3"
              >
                <span className="flex items-center text-base sm:text-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10 mr-4 text-stone-500 hidden sm:block"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="font-bold mr-1">
                    D<span className="hidden sm:inline">ay </span>3:
                  </span>
                  Alftavatn - Emstrur
                </span>
                <svg
                  data-accordion-icon=""
                  className="w-6 h-6 shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </h2>
            <div
              id="accordion-open-body-3"
              className="hidden"
              aria-labelledby="accordion-open-heading-3"
            >
              <div className="p-5 font-light border border-t-0 border-gray-200 dark:border-gray-700">
                <div className="flex flex-row">
                  <div className="flex flex-row m-4 ml-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-10 w-10 block mr-4 text-stone-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12 1.586l-4 4v12.828l4-4V1.586zM3.707 3.293A1 1 0 002 4v10a1 1 0 00.293.707L6 18.414V5.586L3.707 3.293zM17.707 5.293L14 1.586v12.828l2.293 2.293A1 1 0 0018 16V6a1 1 0 00-.293-.707z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>
                      <span className="block font-bold">Distance</span>
                      16 km (10 mi)
                    </span>
                  </div>
                  <div className="flex flex-row m-4">
                    <span>
                      <span className="block font-bold">Time</span>
                      6-7 hours
                    </span>
                  </div>
                </div>
                <p className="text-gray-500 dark:text-gray-400">
                  From
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Álftavatn
                  </a>
                  , the trail goes in an easterly direction over
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Brattháls
                  </a>
                  and continues east to
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Hvanngil
                  </a>
                  . The
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Bratthálskvísl
                  </a>
                  river is without a bridge and needs to be forded by foot which
                  in most cases is relatively easy. From the hill above
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Hvanngil
                  </a>
                  the view is magnificent.
                </p>
                <p className="mt-2 text-gray-500 dark:text-gray-400">
                  In
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Hvanngil
                  </a>
                  hikers can use the toilet facilities. From
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Hvanngil
                  </a>
                  , there is a short walk to the river
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Kaldaklofskvísl
                  </a>
                  , which can be crossed on a footbridge. Just south, is another
                  river,
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Bláfjallakvísl
                  </a>
                  without a bridge. In most cases, it is not so difficult to
                  cross but care is needed, especially during heavy rain when
                  the river can grow fast.
                </p>
                <p className="mt-2 text-gray-500 dark:text-gray-400">
                  Now the route lies mostly on the main road until the river
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Innri-Emstruá
                  </a>
                  is reached. It has a bridge. Occasionally you have to tread
                  through some water as there is an overflow that bypasses the
                  bridge. Just south of it, the trail turns from the main road
                  to the left and south to
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Emstrur
                  </a>
                  , where the land is practically without vegetation. If the
                  weather is dry, with strong wind, especially from north, the
                  sand can drift. The trail lies between two mountains called
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Útigönguhöfðar
                  </a>
                  and in about an hour the
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Botnar
                  </a>
                  huts in
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Emstrur
                  </a>
                  is reached. The cabins are not visible until you are almost
                  completely next to them.
                </p>
              </div>
            </div>
            <h2 id="accordion-open-heading-4">
              <button
                type="button"
                className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
                data-accordion-target="#accordion-open-body-4"
                aria-expanded="false"
                aria-controls="accordion-open-body-4"
              >
                <span className="flex items-center text-base sm:text-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10 mr-4 text-stone-500 hidden sm:block"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="font-bold mr-1">
                    D<span className="hidden sm:inline">ay </span>4:
                  </span>
                  Emstrur - Thorsmork - Reykjavik
                </span>
                <svg
                  data-accordion-icon=""
                  className="w-6 h-6 shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </h2>
            <div
              id="accordion-open-body-4"
              className="hidden"
              aria-labelledby="accordion-open-heading-4"
            >
              <div className="p-5 font-light border border-t-0 border-gray-200 dark:border-gray-700">
                <div className="flex flex-row">
                  <div className="flex flex-row m-4 ml-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-10 w-10 block mr-4 text-stone-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12 1.586l-4 4v12.828l4-4V1.586zM3.707 3.293A1 1 0 002 4v10a1 1 0 00.293.707L6 18.414V5.586L3.707 3.293zM17.707 5.293L14 1.586v12.828l2.293 2.293A1 1 0 0018 16V6a1 1 0 00-.293-.707z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span>
                      <span className="block font-bold">Distance</span>
                      15 km (9 mi)
                    </span>
                  </div>
                  <div className="flex flex-row m-4">
                    <span>
                      <span className="block font-bold">Time</span>
                      6-7 hours
                    </span>
                  </div>
                </div>
                <p className="text-gray-500 dark:text-gray-400">
                  The trail heads towards the east from the huts in
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Botnar
                  </a>
                  . Shortly, the bridge over the river
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Syðri-Emstruá
                  </a>
                  is reached. The river flows into a narrow deep canyon that
                  reaches most of the way to
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Entujökull
                  </a>
                  . People with vertigo sometimes struggle to get to and across
                  the bridge. From there, the path lies along
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Langháls
                  </a>
                  and towards the junction of the rivers
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Markarfljót
                  </a>
                  and
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Syðri-Emstruá
                  </a>
                  . It is recommended to walk to the edge of the gorge where the
                  rivers meet, before hiking south through
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Almenningar
                  </a>
                  .
                </p>
                <p className="mt-2 text-gray-500 dark:text-gray-400">
                  Soon the trail runs through two small ravines,
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Slyppugil
                  </a>
                  and
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Bjórgil
                  </a>
                  . In each of them there is a little creek with drinkable water
                  making this an excellent spot to rest and have lunch. After
                  hiking up from the latter ravine,
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Bjórgil
                  </a>
                  , the trail leads to
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Fauskatorfur
                  </a>
                  and gradually to an area with more vegetation. It is called
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Úthólmar
                  </a>
                  . The hill upwards from the river
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Ljósá
                  </a>
                  is called
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Kápa
                  </a>
                  or "coat", and is the last steep on the trail.
                </p>
                <p className="mt-2 text-gray-500 dark:text-gray-400">
                  When the trails comes down from
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Kápa
                  </a>
                  , hikers have to ford the river
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Þröngá
                  </a>
                  which in most cases is not difficult but can be quite rocky.
                  The river marks the boundary of
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Þórsmörk
                  </a>
                  and now there is only a bit more than half an hour hike
                  through the pleasant woodland
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Hamraskógar
                  </a>
                  until you arrive at the
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Skagfjörðsskáli
                  </a>
                  cabin in
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Langidalur
                  </a>
                  ,
                  <a className="text-blue-600 dark:text-blue-500 hover:underline">
                    Þórsmörk
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>

        <section className="text-gray-700">
          <div className="flex flex-col p-4 laptop:p-16 w-full max-w-[1408px] pt-0 laptop:pt-0">
            <div className="grid grid-cols-6">
              <div className="overflow-hidden flex flex-wrap h-64 w-full md:w-1/2 lg:w-2/6">
                <div className="w-full p-1 md:p-2 h-full">
                  <img
                    alt="gallery"
                    className="block object-cover object-center w-full h-full rounded-lg"
                    src="/img/4.webp"
                  />
                </div>
              </div>
              <div className="overflow-hidden flex flex-wrap h-64 w-full md:w-1/2 lg:w-2/6">
                <div className="w-full p-1 md:p-2 h-full">
                  <img
                    alt="gallery"
                    className="block object-cover object-center w-full h-full rounded-lg"
                    src="/img/7.webp"
                  />
                </div>
              </div>
              <div className="overflow-hidden flex flex-wrap h-64 w-full md:w-1/2 lg:w-2/6">
                <div className="w-full p-1 md:p-2 h-full">
                  <img
                    alt="gallery"
                    className="block object-cover object-center w-full h-full rounded-lg"
                    src="/img/Landmannalaugar - Graenagil.jpg"
                  />
                </div>
              </div>
              <div className="overflow-hidden flex flex-wrap h-64 w-full md:w-1/2 lg:w-2/6">
                <div className="w-full p-1 md:p-2 h-full">
                  <img
                    alt="gallery"
                    className="block object-cover object-center w-full h-full rounded-lg"
                    src="/img/Storasula on the way from Alftavatn to Emstrur.jpg"
                  />
                </div>
              </div>
              <div className="overflow-hidden flex flex-wrap h-64 w-full md:w-1/2 lg:w-2/6">
                <div className="w-full p-1 md:p-2 h-full">
                  <img
                    alt="gallery"
                    className="block object-cover object-center w-full h-full rounded-lg"
                    src="/img/Thorsmork - Guide and Eyjafjallajokull.jpg"
                  />
                </div>
              </div>
              <div className="overflow-hidden flex flex-wrap h-64 w-full md:w-1/2 lg:w-2/6">
                <div className="w-full p-1 md:p-2 h-full">
                  <img
                    alt="gallery"
                    className="block object-cover object-center w-full h-full rounded-lg"
                    src="/img/Hiking in Thorsmork 2.jpg"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="grid grid-cols-2 p-4 laptop:p-16 w-full max-w-[1408px] pt-0 laptop:pt-0">
          <div className="flex flex-col">
            <section className="text-gray-700">
              <div className="max-w-7xl px-5 py-2 mx-auto">
                <div className="flex flex-wrap -m-1 md:-m-2">
                  <div className="flex flex-wrap w-full md:w-1/2 lg:w-2/6">
                    <div className="w-full p-1 md:p-2">
                      <p className="mt-6 text-xl font-bold text-black lg:mx-auto">
                        Included
                      </p>
                      <p className="mt-4 text-gray-500 lg:mx-auto">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 inline-block -mt-1 text-green-900"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        Qualified and professional guide
                      </p>
                      <p className="mt-4 text-gray-500 lg:mx-auto">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 inline-block -mt-1 text-green-900"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        Mountain hut sleeping bag accommodation in dormitories
                      </p>
                      <p className="mt-4 text-gray-500 lg:mx-auto">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 inline-block -mt-1 text-green-900"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        Transport at the beginning and end of the hike
                      </p>
                      <p className="mt-4 text-gray-500 lg:mx-auto">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 inline-block -mt-1 text-green-900"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        Transfer of luggage between huts
                      </p>
                      <p className="mt-4 text-gray-500 lg:mx-auto">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 inline-block -mt-1 text-green-900"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        All meals (breakfast, lunch, dinner, snacks)
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-wrap w-full md:w-1/2 lg:w-2/6">
                    <div className="w-full p-1 md:p-2">
                      <p className="mt-6 text-xl font-bold text-black lg:mx-auto">
                        Not Included
                      </p>
                      <p className="mt-4 text-gray-500 lg:mx-auto">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 inline-block -mt-1 text-red-900"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        Sleeping bag
                      </p>
                      <p className="mt-4 text-gray-500 lg:mx-auto">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 inline-block -mt-1 text-red-900"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        Showers in huts (500 ISK, payable on site)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="mx-auto mt-5 px-5 py-2 max-w-7xl w-full">
              <div className="mb-8 lg:text-center">
                <p className="text-xl font-bold text-black lg:mx-auto">
                  FREQUENTLY ASKED QUESTIONS
                </p>
              </div>

              <div
                id="accordion-collapse"
                data-accordion="collapse"
                className="max-w-7xl mx-auto"
              >
                <h2 id="accordion-collapse-heading-1">
                  <button
                    type="button"
                    className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
                    data-accordion-target="#accordion-collapse-body-1"
                    aria-expanded="false"
                    aria-controls="accordion-collapse-body-1"
                  >
                    <span>What should I bring?</span>
                    <svg
                      data-accordion-icon=""
                      className="w-6 h-6 shrink-0"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </h2>
                <div
                  id="accordion-collapse-body-1"
                  className="hidden"
                  aria-labelledby="accordion-collapse-heading-1"
                >
                  <div className="p-5 font-light border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                    <p className="mb-2 text-gray-500 dark:text-gray-400">
                      We strongly suggest having good quality hiking clothes and
                      gear, including rain-gear, tops and bottoms!
                    </p>
                    <p className="mb-6 text-gray-500 dark:text-gray-400">
                      Cotton clothing is not appropriate, modern outdoor
                      clothing is by far more comfortable and will greatly
                      improve your experience.
                    </p>

                    <p className="mb-2 font-bold text-gray-500 dark:text-gray-400">
                      Packing list
                    </p>
                    <ul className="pl-5 text-gray-500 list-disc dark:text-gray-400">
                      <li>
                        A waterproof duffel bag for the transport of your
                        overnight gear between huts. Please avoid bringing a
                        suitcase!
                      </li>
                      <li>
                        Day backpack for extra clothes and lunch pack during the
                        day. 25 – 40L (1500 – 2500 cu in). And a backpack cover
                        for it
                      </li>
                      <li>
                        Good hiking shoes with ankle support and 2-3 pairs of
                        soft wool or fleece hiking socks
                      </li>
                      <li>
                        Long sleeve shirt (thermal underwear), wool or fleece
                      </li>
                      <li>T-shirt (thermal underwear), Wool or synthetic.</li>
                      <li>
                        Light sweater (2nd layer), wool, fleece or similar
                      </li>
                      <li>Gloves - wool or fleece</li>
                      <li>
                        Jacket with a good hood – windproof, waterproof and
                        breathable, e.g. Gore-Tex or comparable
                      </li>
                      <li>
                        Hiking pants/shorts, something strong and light that
                        dries quickly e.g. soft-shell
                      </li>
                      <li>
                        Rain trousers – windproof, waterproof and breathable.
                        Please note that full raingear is mandatory in Iceland,
                        poncho is not useful because of wind
                      </li>
                      <li>
                        River crossing shoes - Crocs are a good choice, open
                        sandals or flip-flops will not do the job
                      </li>
                      <li>Warm hat - wool or synthetic</li>
                      <li>Water bottle</li>
                      <li>Light towel, easily packable</li>
                      <li>Hiking poles</li>
                      <li>Sunglasses/ski goggles</li>
                      <li>Sun protection and lip balm</li>
                      <li>
                        Second skin, band-aid, elastic bandage and painkillers
                      </li>
                      <li>Buff around the neck</li>
                      <li>Wind- and waterproof protective clothes</li>
                      <li>Toothbrush and toothpaste</li>
                      <li>Soap/shampoo</li>
                      <li>
                        Cash - for the showers, normally around 500 ISK per
                        shower
                      </li>
                      <li>Power-bank / extra battery for your electronics</li>
                      <li>Lunch box to pack each days lunch into</li>
                      <li>
                        Swimwear if you want to bathe in the hot geothermal pool
                        in Landmannalaugar
                      </li>
                      <li>
                        Sleeping bag. A basic sleeping bag with no specific
                        temperature rating is sufficient for this trek since all
                        the huts are heated
                      </li>
                      <li>Earplugs</li>
                      <li>Slippers for indoor use</li>
                      <li>Clothes for wearing in the hut</li>
                    </ul>
                  </div>
                </div>
                <h2 id="accordion-collapse-heading-10">
                  <button
                    type="button"
                    className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
                    data-accordion-target="#accordion-collapse-body-10"
                    aria-expanded="false"
                    aria-controls="accordion-collapse-body-10"
                  >
                    <span>How fit do I need to be?</span>
                    <svg
                      data-accordion-icon=""
                      className="w-6 h-6 shrink-0"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </h2>
                <div
                  id="accordion-collapse-body-10"
                  className="hidden"
                  aria-labelledby="accordion-collapse-heading-10"
                >
                  <div className="p-5 font-light border border-b-0 border-gray-200 dark:border-gray-700">
                    <p className="mb-2 text-gray-500 dark:text-gray-400">
                      As far as long-distance hiking trails go, the Laugavegur
                      is very approachable in terms of difficulty, and we rate
                      it as being a<strong className="font-bold">medium</strong>{" "}
                      difficulty hike.
                    </p>
                    <p className="mb-2 text-gray-500 dark:text-gray-400">
                      The weather and trail conditions may however affect the
                      difficulty.
                    </p>
                    <p className="mb-2 text-gray-500 dark:text-gray-400">
                      You will encounter several
                      <strong className="font-bold">
                        river crossings
                      </strong>{" "}
                      along the trail.
                    </p>
                  </div>
                </div>
                <h2 id="accordion-collapse-heading-2">
                  <button
                    type="button"
                    className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
                    data-accordion-target="#accordion-collapse-body-2"
                    aria-expanded="false"
                    aria-controls="accordion-collapse-body-2"
                  >
                    <span>What facilities are available in the huts?</span>
                    <svg
                      data-accordion-icon=""
                      className="w-6 h-6 shrink-0"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </h2>
                <div
                  id="accordion-collapse-body-2"
                  className="hidden"
                  aria-labelledby="accordion-collapse-heading-2"
                >
                  <div className="p-5 font-light border border-b-0 border-gray-200 dark:border-gray-700">
                    <p className="mb-2 text-gray-500 dark:text-gray-400">
                      All of the huts have showers and toilets, either flushable
                      or outhouses.
                    </p>
                    <p className="mb-2 text-gray-500 dark:text-gray-400">
                      Using the showers costs 500 ISK and can be paid for at the
                      warden’s hut at each hut/campsite.
                    </p>
                    <p className="mb-2 text-gray-500 dark:text-gray-400">
                      The WC have toilet paper and soap for washing your hands.
                      Anything else you might need for example shampoo,
                      bandages, towels, washcloths, nail clippers etc. you will
                      need to bring with you.
                    </p>
                    <p className="mb-2 text-gray-500 dark:text-gray-400">
                      Wifi is not available, but internet connection can
                      sometimes be received around the huts, or by walking a bit
                      around the area.
                    </p>
                    <p className="mb-2 text-gray-500 dark:text-gray-400">
                      The huts feature kitchens and/or grills that the group
                      will cook their meals with.
                    </p>
                    <p className="mb-2 text-gray-500 dark:text-gray-400">
                      You will be able to fill up your water bottle at each hut,
                      or in the rivers as you hike. The river water in Iceland
                      is safe to drink and very delicious.
                    </p>
                    <p className="mb-2 text-gray-500 dark:text-gray-400">
                      There are no power outlets in the huts that are accessible
                      to travelers. We encourage bringing your own power bank on
                      the tour (you can pack that in your duffel bag which is
                      transported by the support vehicle). In some cases, you
                      can buy small portable chargers at the huts as well, but
                      they are not always available.
                    </p>
                  </div>
                </div>
                <h2 id="accordion-collapse-heading-3">
                  <button
                    type="button"
                    className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
                    data-accordion-target="#accordion-collapse-body-3"
                    aria-expanded="false"
                    aria-controls="accordion-collapse-body-3"
                  >
                    <span>Do you offer any dietary options?</span>
                    <svg
                      data-accordion-icon=""
                      className="w-6 h-6 shrink-0"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </h2>
                <div
                  id="accordion-collapse-body-3"
                  className="hidden"
                  aria-labelledby="accordion-collapse-heading-3"
                >
                  <div className="p-5 font-light border border-b-0 border-gray-200 dark:border-gray-700">
                    <p className="mb-2 text-gray-500 dark:text-gray-400">
                      We try our best to accommodate everyone in the group as
                      best we can.
                    </p>
                    <p className="mb-2 text-gray-500 dark:text-gray-400">
                      Please let us know if you have any dietary preferences
                      well in advance of the trip so that we can plan
                      accordingly.
                    </p>
                    <a
                      href="mailto:adventures@norse.is"
                      className="text-blue-600 dark:text-blue-500 hover:underline"
                    >
                      adventures@norse.is
                    </a>
                  </div>
                </div>
                <h2 id="accordion-collapse-heading-4">
                  <button
                    type="button"
                    className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
                    data-accordion-target="#accordion-collapse-body-4"
                    aria-expanded="false"
                    aria-controls="accordion-collapse-body-4"
                  >
                    <span>What is your refund policy?</span>
                    <svg
                      data-accordion-icon=""
                      className="w-6 h-6 shrink-0"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </h2>
                <div
                  id="accordion-collapse-body-4"
                  className="hidden"
                  aria-labelledby="accordion-collapse-heading-4"
                >
                  <div className="p-5 font-light border border-t-0 border-gray-200 dark:border-gray-700">
                    <p className="mb-2 text-gray-500 dark:text-gray-400">
                      Please take a look at our cancellation policy page, as it
                      will give you the information that you need.
                    </p>
                    <ul className="pl-5 text-gray-500 list-disc dark:text-gray-400">
                      <li>
                        <a
                          href="https://norse.is/cancellation-policy"
                          className="text-blue-600 dark:text-blue-500 hover:underline"
                        >
                          Cancellation Policy
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://norse.is/terms-and-conditions"
                          className="text-blue-600 dark:text-blue-500 hover:underline"
                        >
                          Terms &amp; Conditions
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div
            class="bokunWidget flex flex-col w-full"
            data-src="https://widgets.bokun.io/online-sales/b8f8ea4f-4784-493c-bad6-a8541da2e38f/experience-calendar/673913"
          ></div>
          <noscript>Please enable javascript in your browser to book</noscript>
        </div>

        <Footer />
      </div>
    );
  }
}
