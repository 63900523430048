import * as React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import Footer from "../components/Footer";

export default class CancellationPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navActive: false,
      screenWidth: window.innerWidth,
    };

    this.hideNav = this.hideNav.bind(this);
    this.showNav = this.showNav.bind(this);
  }

  hideNav() {
    this.setState({ navActive: false });
  }

  showNav() {
    this.setState({ navActive: true });
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    if (this.state.navActive && window.innerWidth < 1440) {
      document.body.classList.add("overflow-hidden");
    } else document.body.classList.remove("overflow-hidden");

    window.addEventListener("resize", () => {
      this.setState({
        screenWidth: window.innerWidth,
      });
      if (this.state.navActive && window.innerWidth < 1440) {
        document.body.classList.add("overflow-hidden");
      } else document.body.classList.remove("overflow-hidden");
    });
  }

  componentDidUpdate() {
    if (this.state.navActive && window.innerWidth < 1440) {
      document.body.classList.add("overflow-hidden");
    } else document.body.classList.remove("overflow-hidden");
  }

  render() {
    return (
      <div className="App flex flex-col items-center">
        <div className="bg-red-500 text-white w-full p-2">
          The website is in development so you can expect some errors or display
          problems. Not all buttons and links work.
        </div>

        <header
          className={
            "flex flex-col items-center desktop:justify-center w-full group" +
            (this.state.navActive & (this.state.screenWidth < 1440)
              ? " absolute"
              : " h-96 relative")
          }
        >
          <div
            className={
              "z-40 flex flex-row items-center justify-center w-full desktop:h-auto desktop:absolute desktop:top-0 desktop:left-0" +
              (this.state.navActive & (this.state.screenWidth < 1440)
                ? " h-screen flex-col"
                : "")
            }
          >
            <div
              className={
                "w-full h-full desktop:max-w-[1280px] flex bg-neutral-900 text-white desktop:m-16" +
                (this.state.navActive & (this.state.screenWidth < 1440)
                  ? " flex-col items-center justify-center"
                  : " flex-row bg-opacity-90")
              }
            >
              <a href="/">
                <div className="p-8 hover:bg-neutral-900">
                  <img
                    src="/runes.svg"
                    alt="Norse Logo"
                    className="invert w-48"
                  />
                </div>
              </a>
              <div
                id="navlinks"
                className={
                  "flex flex-col desktop:flex-row desktop:ml-auto text-xl font-black" +
                  (!this.state.navActive ? " ml-auto" : "")
                }
              >
                {this.state.navActive || this.state.screenWidth >= 1440 ? (
                  <>
                    <HashLink
                      onClick={this.hideNav}
                      className="p-8 hover:bg-neutral-900 h-full flex flex-col justify-center"
                      to="/#adventures"
                    >
                      ADVENTURES
                    </HashLink>
                    <Link
                      onClick={this.hideNav}
                      className="p-8 hover:bg-neutral-900 h-full flex flex-col justify-center"
                      to="/about-us"
                    >
                      ABOUT US
                    </Link>
                    <a
                      onClick={this.hideNav}
                      className="p-8 hover:bg-neutral-900 h-full flex flex-col justify-center"
                      href="#contact-us"
                    >
                      CONTACT US
                    </a>
                  </>
                ) : (
                  <button onClick={this.showNav} className="text-3xl p-8">
                    ☰
                  </button>
                )}
              </div>
            </div>
          </div>

          {this.state.navActive && (
            <button
              onClick={this.hideNav}
              className="text-3xl p-8 absolute top-0 right-0 text-white z-50"
            >
              ☰
            </button>
          )}

          <div className="w-full h-full object-cover group-hover:brightness-110 bg-blue-500" />
          <div className="absolute w-full h-full bg-black bg-opacity-20"></div>
          <div className="absolute w-full h-full flex flex-col items-start justify-end p-4 laptop:p-16 max-w-[1408px]">
            <div className="flex flex-col gap-4 mb-12 laptop:mb-0">
              <span className="text-4xl text-white font-black drop-shadow-md uppercase">
                Cancellation Policy
              </span>
            </div>
          </div>
        </header>

        <main class="flex flex-col w-full p-4 laptop:p-16 max-w-[1408px] py-16">
          <section class="flex flex-col gap-8">
            <div class="flex flex-col gap-4">
              <p class="text-lg font-medium">For all bookings</p>

              <ul class="text-lg">
                <li>
                  If we cancel for any reason you will receive a full refund.
                </li>
                <li>
                  No refund for any other cancellations than the ones listed
                  here.
                </li>
              </ul>

              <p class="mt-4 text-lg font-medium">For single-day adventures</p>

              <ul class="text-lg">
                <li>Cancel 2 days before the trip for a full refund.</li>
              </ul>

              <p class="mt-4 text-lg font-medium">For multi-day adventures</p>

              <ul class="text-lg">
                <li>Cancel up to 48 hours after booking for a full refund.</li>
                <li>
                  Cancel 60 days or more before the trip for a 70% refund.
                </li>
                <li>Cancel 30 - 59 days before the trip for a 50% refund.</li>
              </ul>
            </div>
          </section>
        </main>

        <Footer />
      </div>
    );
  }
}
