import * as React from "react";
import Star from "./Star";

export default class Reviews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentReview: 0,
      reviewCollection: [],
      screenWidth: window.innerWidth,
    };

    this.previousReview = this.previousReview.bind(this);
    this.nextReview = this.nextReview.bind(this);
  }

  componentDidMount() {
    const query = `
        {
            reviewCollection {
                items {
                    name
                    text
                    rating
                }
            }
        }
        `;

    window
      .fetch("https://graphql.contentful.com/content/v1/spaces/oed9devm8exq", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: "Bearer aoevsO5NpdcHOKWO2ieEPAyiJk0JSSKOSYPBV9W0VYc",
        },
        // send the GraphQL query
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        this.setState({
          reviewCollection: data.reviewCollection.items,
        });
      });

    window.addEventListener("resize", () => {
      this.setState({
        screenWidth: window.innerWidth,
      });
    });
  }

  previousReview() {
    if (this.state.currentReview == 0)
      this.setState({ currentReview: this.state.reviewCollection.length - 1 });
    else this.setState({ currentReview: this.state.currentReview - 1 });

    document.getElementById("reviews").scrollIntoView();
  }

  nextReview() {
    if (this.state.currentReview == this.state.reviewCollection.length - 1)
      this.setState({ currentReview: 0 });
    else this.setState({ currentReview: this.state.currentReview + 1 });

    document.getElementById("reviews").scrollIntoView();
  }

  render() {
    return (
      <div
        id="reviews"
        className="bg-neutral-100 w-full min-h-screen flex flex-col justify-center items-center"
      >
        <div className="w-full max-w-[1280px] min-h-screen py-16 flex flex-col justify-center items-center gap-8">
          {this.state.reviewCollection.length > 0 ? (
            <>
              <div className="flex flex-row w-full gap-4 justify-center text-2xl tablet:text-3xl font-black">
                <Star />
                <span>
                  {(
                    this.state.reviewCollection.reduce(
                      (i, e) => i + e.rating,
                      0
                    ) / this.state.reviewCollection.length
                  ).toFixed(2)}{" "}
                  ({this.state.reviewCollection.length} reviews)
                </span>
              </div>
              <div className="flex flex-col w-full h-max basis-full grow max-w-[1280px]">
                <div className="grid grid-cols-8 gap-8 h-full tablet:grow">
                  <div className="h-min shrink laptop:h-full flex flex-col col-span-4 order-1 laptop:col-span-1 items-center justify-center">
                    <button
                      onClick={this.previousReview}
                      className="text-3xl font-black p-4 h-min laptop:h-full w-full hover:bg-neutral-200"
                    >
                      {"<"}
                    </button>
                  </div>
                  <div className="flex flex-row grow items-center col-span-8 order-3 laptop:order-2 laptop:col-span-6 overflow-hidden mx-4 laptop:mx-16">
                    <div className="flex flex-row gap-8 ">
                      <div className="flex flex-col gap-4 w-full h-full grow">
                        <span>
                          <span className="font-bold">
                            {
                              this.state.reviewCollection[
                                this.state.currentReview
                              ].name
                            }
                          </span>
                        </span>
                        <span className="text-3xl text-blue-500">
                          {[
                            ...Array(
                              this.state.reviewCollection[
                                this.state.currentReview
                              ].rating
                            ),
                          ].map((e) => (
                            <Star />
                          ))}
                        </span>
                        <span>
                          {
                            this.state.reviewCollection[
                              this.state.currentReview
                            ].text
                          }
                        </span>
                      </div>
                      {(this.state.screenWidth >= 1440) &
                      (this.state.reviewCollection.length > 1) ? (
                        <>
                          <div className="flex flex-col gap-4 w-full">
                            <span>
                              <span className="font-bold">
                                {
                                  this.state.reviewCollection[
                                    this.state.currentReview ==
                                    this.state.reviewCollection.length - 1
                                      ? 0
                                      : this.state.currentReview + 1
                                  ].name
                                }
                              </span>
                            </span>
                            <span className="text-3xl text-blue-500">
                              {[
                                ...Array(
                                  this.state.reviewCollection[
                                    this.state.currentReview ==
                                    this.state.reviewCollection.length - 1
                                      ? 0
                                      : this.state.currentReview + 1
                                  ].rating
                                ),
                              ].map((e) => (
                                <Star />
                              ))}
                            </span>
                            <span>
                              {
                                this.state.reviewCollection[
                                  this.state.currentReview ==
                                  this.state.reviewCollection.length - 1
                                    ? 0
                                    : this.state.currentReview + 1
                                ].text
                              }
                            </span>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="h-min shrink laptop:h-full flex flex-col col-span-4 order-2 laptop:order-3 laptop:col-span-1 items-center justify-center">
                    <button
                      onClick={this.nextReview}
                      className="text-3xl font-black p-4 h-min laptop:h-full w-full hover:bg-neutral-200"
                    >
                      {">"}
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <span className="text-3xl">No Reviews Found</span>
          )}
        </div>
      </div>
    );
  }
}
